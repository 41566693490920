import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "_metronic/_helpers";

export default function CardStats({ svg, title, value, svgVariant }) {
    return (
        <div className="card">
            <div className="card-content">
                <div className="card-body">
                    <div className="media d-flex">
                        <div className="align-self-center">
                            <span className={`svg-icon svg-icon-4x svg-icon-${svgVariant || 'primary'}`}>
                                <SVG src={toAbsoluteUrl("/media/svg/icons/" + svg)} />
                            </span>
                        </div>
                        <div className="media-body text-right">
                            <h3 className="font-weight-normal">{ value }</h3>
                            <span className="text-muted font-weight-bold mt-2 font-size-h4">{ title }</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
