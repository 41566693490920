import axios from "axios";

export const URL = "admin/alarm";

// CREATE =>  POST: add a new entity to the server
export function create(data) {
    return axios.post(URL, data);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function list(queryParams) {
    // const where = { role: 'admin'};
    // return axios.post(`${URL}/datatable`, { queryParams: { ...queryParams, where }});
    return axios.post(`${URL}/datatable`, { queryParams });
}

export function find(id) {
    return axios.get(`${URL}/${id}`);
}

// UPDATE => PUT: update the entity on the server
export function update(data) {
    return axios.put(`${URL}/${data.id}`, data);
}

// DELETE => delete the entity from the server
export function remove(id) {
    return axios.delete(`${URL}/${id}`);
}

export function importExcel(data) {
    const formData = new FormData();
    for (let key of Object.keys(data)) formData.append(key, data[key]);
    return axios.post(`${URL}/import`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

export function exportData() {
    return axios.get(`${URL}/data/export` )
}
