import React, {Suspense, lazy} from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {DashboardPage} from "./pages/DashboardPage";
// import {BuilderPage} from "./pages/BuilderPage";
// import {MyPage} from "./pages/MyPage";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );

// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );

const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const IOTPage = lazy(() =>
    import("./modules/IOT/pages/IOTPage")
);
const CommonPage = lazy(() =>
    import("./modules/Common/pages/CommonPage")
);
const AccountPage = lazy(() =>
    import("./modules/Account/pages/AccountPage")
);
const SitePage = lazy(() =>
    import("./modules/Site/pages/SitePage")
);
export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                {/*<ContentRoute path="/builder" component={BuilderPage}/>*/}
                {/*<ContentRoute path="/my-page" component={MyPage}/>*/}
                {/*<Route path="/google-material" component={GoogleMaterialPage}/>*/}
                {/*<Route path="/react-bootstrap" component={ReactBootstrapPage}/>*/}
                <Route path="/e-commerce" component={ECommercePage}/>
                <Route path="/iot" component={IOTPage}/>
                <Route path="/common" component={CommonPage}/>
                <Route path="/account" component={AccountPage}/>
                <Route path="/site" component={SitePage}/>

                {/*<Redirect to="error/error-v1"/>*/}
                <Redirect to="error"/>
            </Switch>
        </Suspense>
    );
}
