import * as React from 'react'
import { FieldProps } from 'formik'
import Select from 'react-select'

export const MySelect = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    onChange,
    isMulti = false,
    value,
     withFeedbackLabel = true,
     customFeedbackLabel,
     type = "select",
    ...props
}: FieldProps & { label: string; options: Array<{ value: string; label: string }> }) => {
    const { options } = props

    const getValue = () => {
        const ids = value || field.value;
        if (isMulti) {
            return options ? options.filter(e => ids.includes(e.value)) : []
        }
        return options ? options.find(option => option.value === ids) : '';
    }

    return (
        <>
            { props.label && <label htmlFor={field.name}>{props.label}</label> }
            <Select
                {...field}
                {...props}
                isMulti={isMulti}
                options={options}
                value={getValue()}
                onChange={option => {
                    setFieldTouched(field.name, true);
                    if (isMulti){
                        setFieldValue(field.name, (option || []).map(e => e.value));
                    } else {
                        setFieldValue(field.name, option ? option.value : null);
                    }
                    if (onChange) onChange(option);
                }}
            />
            {errors[field.name] && (
                <div className="text-danger">{errors[field.name]}</div>
            )}
        </>
    )
}

// import React from "react";
// import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
//
// const getFieldCSSClasses = (touched, errors) => {
//   const classes = ["form-control"];
//   if (touched && errors) {
//     classes.push("is-invalid");
//   }
//
//   if (touched && !errors) {
//     classes.push("is-valid");
//   }
//
//   return classes.join(" ");
// };
//
// export function MySelect({
//       field, // { name, value, onChange, onBlur }
//       form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
//       label,
//       withFeedbackLabel = true,
//       customFeedbackLabel,
//       type = "text",
//       list = [],
//       ...props
//     }) {
//   return (
//       <>
//         {label && <label>Enter {label}</label>}
//         <select
//             className={getFieldCSSClasses(touched[field.name], errors[field.name])}
//             {...field}
//             {...props}
//           >
//             <option></option>
//             {list.map(e => <option key={e.id} value={e.id}>{e.title}</option>)}
//           </select>
//
//       {/*<input*/}
//       {/*      type={type}*/}
//       {/*      className={getFieldCSSClasses(touched[field.name], errors[field.name])}*/}
//       {/*      {...field}*/}
//       {/*      {...props}*/}
//       {/*  />*/}
//         {withFeedbackLabel && (
//             <FieldFeedbackLabel
//                 error={errors[field.name]}
//                 touched={touched[field.name]}
//                 label={label}
//                 type={type}
//                 customFeedbackLabel={customFeedbackLabel}
//             />
//         )}
//       </>
//   );
// }
//
//
// // import React from "react";
// // import {useField} from "formik";
// // import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
// //
// // const getFieldCSSClasses = (touched, errors) => {
// //   const classes = ["form-control", "form-control-solid"];
// //   if (touched && errors) {
// //     classes.push("is-invalid-select");
// //   }
// //
// //   if (touched && !errors) {
// //     classes.push("is-valid-select");
// //   }
// //
// //   return classes.join(" ");
// // };
// //
// // export function Select({
// //   label,
// //   withFeedbackLabel = true,
// //   type = "text",
// //   customFeedbackLabel,
// //   children,
// //   ...props
// // }) {
// //   const [field, meta] = useField(props);
// //   const { touched, error } = meta;
// //   return (
// //     <>
// //       {label && <label>Select {label}</label>}
// //       <select
// //         className={getFieldCSSClasses(touched, error)}
// //         {...field}
// //         {...props}
// //       >
// //         {children}
// //       </select>
// //       {withFeedbackLabel && (
// //         <FieldFeedbackLabel
// //           error={error}
// //           touched={touched}
// //           label={label}
// //           customFeedbackLabel={customFeedbackLabel}
// //         />
// //       )}
// //     </>
// //   );
// // }
