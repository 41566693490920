import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class DashboardDevices extends Component {
    constructor(props) {
        super(props);

        const data = props.data || [];
        this.state = {
            series: data.map(e => e.value),
            options: {
                chart: {
                    width: 380, type: 'donut'
                },
                labels: data.map(e => e.key),
                dataLabels: {
                    enabled: false
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            show: false
                        }
                    }
                }],
                legend: {
                    position: 'right',
                    offsetY: 0,
                    height: 230,
                }
            },


        };
    }

    render() {
        return (
            <div className="card">
                <div className="card-content">
                    <div className="card-body">
                        <div className="chart-wrap">
                            <div id="chart">
                                <ReactApexChart options={this.state.options} series={this.state.series} type="donut"  width={"100%"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardDevices;
