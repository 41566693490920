import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

// import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
// import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

import { sensorSlice } from "../app/modules/IOT/_redux/sensor/sensorSlice";
import { sensorTypeSlice } from "../app/modules/IOT/_redux/sensor-type/sensorTypeSlice";
import { deviceSlice } from "../app/modules/IOT/_redux/device/deviceSlice";
import { deviceTypeSlice } from "../app/modules/IOT/_redux/device-type/deviceTypeSlice";
import { readingSlice } from "../app/modules/IOT/_redux/reading/readingSlice";
import { alarmSlice } from "../app/modules/IOT/_redux/alarm";
import { actionDeviceSlice } from "../app/modules/IOT/_redux/action-device";

import { mediaSlice } from "../app/modules/Common/_redux/media/mediaSlice";
import { termSlice } from "../app/modules/Common/_redux/term/termSlice";
import { settingsSlice } from "../app/modules/Common/_redux/settings/settingsSlice";
import { feedbackSlice } from "../app/modules/Common/_redux/feedback/feedbackSlice";
import { faqSlice } from "../app/modules/Common/_redux/faq/faqSlice";
import { faqQuestionSlice } from "../app/modules/Common/_redux/faq-question/faqQuestionSlice";

import { customerSlice } from "../app/modules/Account/_redux/customer/customerSlice";
import { managerSlice } from "../app/modules/Account/_redux/manager/managerSlice";
import { tokenSlice } from "../app/modules/Account/_redux/token";
import { emailVerificationSlice } from "../app/modules/Account/_redux/email-verification";
import { sliderSlice } from "../app/modules/Site/_redux/slider";
import { serviceSlice } from "../app/modules/Site/_redux/service";
import { videoSlice } from "../app/modules/Site/_redux/video";
import { productSlice } from "../app/modules/Site/_redux/product";
import { logicSlice } from "../app/modules/IOT/_redux/logic";

export const rootReducer = combineReducers({
    // customers: customersSlice.reducer,
    // products: productsSlice.reducer,
    // remarks: remarksSlice.reducer,
    // specifications: specificationsSlice.reducer,
    auth: auth.reducer,
    sensorType: sensorTypeSlice.reducer,
    sensor: sensorSlice.reducer,
    device: deviceSlice.reducer,
    deviceType: deviceTypeSlice.reducer,
    media: mediaSlice.reducer,
    term: termSlice.reducer,
    settings: settingsSlice.reducer,
    feedback: feedbackSlice.reducer,
    faq: faqSlice.reducer,
    faqQuestion: faqQuestionSlice.reducer,
    customer: customerSlice.reducer,
    manager: managerSlice.reducer,
    token: tokenSlice.reducer,
    emailVerification: emailVerificationSlice.reducer,
    reading: readingSlice.reducer,
    slider: sliderSlice.reducer,
    service: serviceSlice.reducer,
    video: videoSlice.reducer,
    product: productSlice.reducer,
    actionDevice: actionDeviceSlice.reducer,
    alarm: alarmSlice.reducer,
    logic: logicSlice.reducer
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
