import React from 'react';
import GoogleMapReact from 'google-map-react';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

const AnyReactComponent = ({ color }) => <i role='button' className={`fa fa-map-marker-alt fa-2x text-${color || 'danger'}`}/>;

export function SimpleMap({ locations = [] }) {

    const center = locations[0] || { lat: 59.95, lng: 30.33 };

    const defaults = {
        center: { lat: Number(center.lat), lng: Number(center.lng)},
        zoom: 13
    };

    return <div style={{ height: '350px', width: '100%' }}>

        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyC9Kd3A7uIl5IXNF6iq3zwxRqFeOmUyAZ8' }}
            defaultCenter={defaults.center}
            defaultZoom={defaults.zoom}
            options={
                 {
                     styles:
                         [
                             {
                                 "featureType": "administrative",
                                 "elementType": "labels.text.fill",
                                 "stylers": [
                                     {
                                         "color": "#444444"
                                     }
                                 ]
                             },
                             {
                                 "featureType": "landscape",
                                 "elementType": "all",
                                 "stylers": [
                                     {
                                         "color": "#f2f2f2"
                                     }
                                 ]
                             },
                             {
                                 "featureType": "poi",
                                 "elementType": "all",
                                 "stylers": [
                                     {
                                         "visibility": "off"
                                     }
                                 ]
                             },
                             {
                                 "featureType": "road",
                                 "elementType": "all",
                                 "stylers": [
                                     {
                                         "saturation": -100
                                     },
                                     {
                                         "lightness": 45
                                     }
                                 ]
                             },
                             {
                                 "featureType": "road.highway",
                                 "elementType": "all",
                                 "stylers": [
                                     {
                                         "visibility": "simplified"
                                     }
                                 ]
                             },
                             {
                                 "featureType": "road.arterial",
                                 "elementType": "labels.icon",
                                 "stylers": [
                                     {
                                         "visibility": "off"
                                     }
                                 ]
                             },
                             {
                                 "featureType": "transit",
                                 "elementType": "all",
                                 "stylers": [
                                     {
                                         "visibility": "off"
                                     }
                                 ]
                             },
                             {
                                 "featureType": "water",
                                 "elementType": "all",
                                 "stylers": [
                                     {
                                         "color": "#46bcec"
                                     },
                                     {
                                         "visibility": "on"
                                     }
                                 ]
                             }
                         ]}
            }
        >
            {
                locations.filter(e => e.user).map((e, i) =>
                    <div key={i} lat={Number(e.lat)} lng={Number(e.lng)}>
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    {e.user.name}<br />{e.user.email}<br />{e.user.countryCode+' '+e.user.phone}
                            </Tooltip>}
                        >
                            <Button variant="none">
                                <AnyReactComponent color={e.master ? 'danger' : 'warning'} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                )
            }
        </GoogleMapReact>
    </div>;
}
