import React from "react";
import {AsideMenuItem} from "./AsideMenuItem";
import {AsideSubmenu} from "./AsideSubmenu";
import {AsideMenuSeperator} from "./AsideMenuSeperator";


export function AsideMenuList({ layoutProps }) {

  return (
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

          <AsideMenuItem title='Dashboard' url='/dashboard' svg='Design/Layers.svg' />

          <AsideMenuSeperator title='System'/>

          <AsideSubmenu title='Account' url='/account' svg='General/User.svg'>
              <AsideMenuItem title='Customer' url='/account/customer' isSub />
              <AsideMenuItem title='Manager' url='/account/manager' isSub role='super-admin' />
              <AsideMenuItem title='Token' url='/account/token' isSub role='super-admin' />
              <AsideMenuItem title='Email Verification' url='/account/email-verification' isSub />
          </AsideSubmenu>

          <AsideMenuSeperator title='IOT'/>

          <AsideSubmenu title='Resources' url='/iot/resources' svg='Electric/Washer.svg'>
              <AsideMenuItem title='Types' url='/iot/resources-type' isSub />
              <AsideMenuItem title='Devices' url='/iot/resources-device' isSub />
              <AsideMenuItem title='Sensors' url='/iot/resources-sensor' isSub />
              <AsideMenuItem title='Alarms' url='/iot/resources-alarm' isSub />
              <AsideMenuItem title='Logics' url='/iot/resources-logic' isSub />
          </AsideSubmenu>

          <AsideMenuItem title='Reading' url='/iot/reading' svg='Home/Book.svg' />

          <AsideMenuSeperator title='General'/>

          <AsideMenuItem title='Feedback' url='/common/feedback' svg='Communication/Mail.svg' />

          <AsideSubmenu title='FAQ' url='/common/faq' svg='Code/Question-circle.svg'>
              <AsideMenuItem title='Sections' url='/common/faqs' isSub />
              <AsideMenuItem title='Questions' url='/common/faq-questions' isSub />
          </AsideSubmenu>

          <AsideMenuItem title='Media' url='/common/media' svg='Design/Image.svg' />
          <AsideMenuItem title='Terms' url='/common/term' svg='Home/Book.svg' />
          <AsideSubmenu title='Site' url='/site' svg='Home/Globe.svg'>
              <AsideMenuItem title='Product' url='/site/product' isSub />
              <AsideMenuItem title='Service' url='/site/service' isSub />
              <AsideMenuItem title='Slider' url='/site/slider' isSub />
              <AsideMenuItem title='Video' url='/site/video' isSub />
          </AsideSubmenu>
          <AsideMenuItem title='Settings' url='/common/settings' svg='General/Settings-1.svg' />

          <AsideMenuSeperator title='Docs'/>

          <AsideMenuItem title='API Docs' url='https://api.dodotik.com/docs' svg='Home/Book-open.svg' isExternal />

      </ul>
  );
}
