import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class ChartDevicesByCategory extends Component {
    constructor(props) {
        super(props);
        const data = props.data || [];
        this.state = {
            series: [{
                data: data.map(e => e.value)
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'bar',
                    events: {
                        click: function(chart, w, e) {
                            // console.log(chart, w, e)
                        }
                    }
                },

                // colors: colors,
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: props.distributed || false,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val;
                    },
                    offsetY: -28,
                    style: {
                        fontSize: '14px',
                        colors: ["#645d6a"]
                    }
                },

                legend: {
                    show: false
                },
                xaxis: {
                    categories: data.map(e => e.key),
                    labels: {
                        style: {
                            // colors: colors,
                            fontSize: '12px'
                        }
                    }
                }
            },


        };
    }

    render() {
        return (
            <div className="card">
                <div className="card-content">
                    <div className="card-body">
                        <div className="chart-wrap">
                            <div id="chart">
                                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChartDevicesByCategory;
