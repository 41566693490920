import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class ReadingsChart extends Component {
    constructor(props) {
        super(props);
        const data = props.data || [];

        this.state = {
            series: [{
                name: "Readings Count",
                data: data.map(e => e.value)
            }],
            options: {
                chart: {
                    type: 'area',
                    height: 350,
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },

                title: {
                    text: 'Reading Analysis',
                    align: 'left'
                },
                subtitle: {
                    text: 'Readings count per day',
                    align: 'left'
                },
                labels: data.map(e => e.key),
                xaxis: {
                    type: 'datetime',
                },
                yaxis: {
                    opposite: true
                },
                legend: {
                    horizontalAlign: 'left'
                }
            },


        };
    }

    render() {
        return (
            <div className="card">
                <div className="card-content">
                    <div className="card-body">
                        <div className="chart-wrap">
                            <div id="chart">
                                <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReadingsChart;
