import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  forEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const sensorSlice = createSlice({

  name: "sensor",
  initialState: initialState,

  reducers: {

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    imported: (state, action) => {
      state.actionsLoading = false;
    },

    // getById
    fetched: (state, action) => {
      state.actionsLoading = false;
      state.forEdit = action.payload.forEdit;
      state.error = null;
    },

    // list
    list: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.forEdit = undefined;
    },

    // create
    created: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.entity);
    },

    // update
    updated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.model.id) {
          return action.payload.model;
        }
        return entity;
      });
    },

    // delete
    deleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },

  }

});
