/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";


export function UserDropdown() {

    const {user} = useSelector(state => state.auth);

    return (
        <Dropdown drop="down" alignRight>
            <Dropdown.Toggle id="dropdown-toggle-my-cart" as={DropdownTopbarItemToggler}>
                <div className="topbar-item">
                    <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
                        <>
                            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                  {user.name}
                </span>
                            <span className="symbol symbol-35 symbol-light-success">
                    <span className="symbol-label font-size-h5 font-weight-bold">
                      {user.name[0]}
                    </span>
                </span>
                        </>
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
                <ul className="navi navi-hover py-4">
                    <li className="navi-item">
                        <Link to="/logout" className="navi-link">
                            <span className="navi-text">Sign Out</span>
                        </Link>
                    </li>
                </ul>
            </Dropdown.Menu>
        </Dropdown>
    );
}
