import * as server from "./crud";
import {slice, callTypes} from "./slice";

const {actions} = slice;

export const list = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return server
    .list(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.list({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find types";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetch = id => dispatch => {
  if (!id) {
    return dispatch(actions.fetched({ forEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return server
    .find(id)
    .then(response => {
      dispatch(actions.fetched({ forEdit: response.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find type";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const remove = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return server
    .remove(id)
    .then(response => {
      dispatch(actions.feleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't remove type";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const create = data => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  console.log('posting', data);
  try {
    const response = await server.create(data);
    const entity = response.data;
    dispatch(actions.created({ entity }));
    return true;
  } catch (error) {
    error.clientMessage = "Can't create type";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    throw error;
  }
};

export const update = model => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await server.update(model);
    dispatch(actions.updated({ model }));
  } catch (error) {
    error.clientMessage = "Can't update type";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    throw error;
  }
};

export const importExcel = data => async dispatch => {
  dispatch(actions.startCall({callType: callTypes.action}));
  try {
    await server.importExcel(data);
    dispatch(actions.imported());
    return true;
  } catch (error) {
    error.clientMessage = "Can't create type";
    dispatch(actions.catchError({error, callType: callTypes.action}));
    throw error;
  }
};

export const exportExcel = () => {
  return server.exportData();
};
