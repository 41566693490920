import React, { useEffect, useState } from "react";
import { DashboardDevices, CardStats, ChartDevicesByCategory, ChartLine } from "app/widgets";
import axios from "axios";
import { Badge } from "react-bootstrap";

import { SimpleMap } from "app/components/SimpleMap";

export function Dashboard() {

    // const uiService = useHtmlClassService();
    // const layoutProps = useMemo(() => {
    //     return {
    //         demo: objectPath.get(
    //             uiService.config,
    //             "demo"
    //         )};
    // }, [uiService]);

    const [stats, setStats] = useState({});

    useEffect(() => {
        axios.get('admin/statistics/dashboard').then(res => setStats(res.data));
    }, []);

    return <>
        <div className="row">
            <div className="col-xl-3 mt-4">
                <CardStats title="Total Users" svg="Communication/Group.svg"
                           value={stats.users ? `${stats.users.total}` : '--'} svgVariant="light"/>
            </div>
            <div className="col-xl-3 mt-4">
                <CardStats title="Active Users" svg="Communication/Group.svg"
                           value={stats.users ? `${stats.users.active}` : '--'} svgVariant="primary"/>
            </div>
            <div className="col-xl-3 mt-4">
                <CardStats title="Shares" svg="Files/Share.svg" value={stats.users ? stats.users.shares : '--'}
                           svgVariant="info"/>
            </div>
            <div className="col-xl-3 mt-4">
                <CardStats title="Feedback" svg="Communication/Mail.svg" value={stats.feedback ? stats.feedback : '--'}
                           svgVariant="danger"/>
            </div>

            <div className="col-xl-3 mt-4">
                <CardStats title="Total Devices" svg="Electric/Washer.svg"
                           value={stats.devices ? `${stats.devices.total}` : '--'} svgVariant="light"/>
            </div>
            <div className="col-xl-3 mt-4">
                <CardStats title="Active Devices" svg="Electric/Washer.svg"
                           value={stats.users ? `${stats.devices.active}` : '--'} svgVariant="primary"/>
            </div>
            <div className="col-xl-6 mt-4">
                <CardStats title="Readings" svg="Home/Book.svg" value={stats.readings ? stats.readings.total : '--'}
                           svgVariant="warning"/>
            </div>
        </div>
        <div className='row'>
            <div className="col-12 mt-4">
                {
                    stats.readings &&
                    <ChartLine
                        data={stats.readings.chart}
                        title={{ text: 'Reading Analysis', align: 'left' }}
                        subtitle={{ text: 'Readings count per day', align: 'left' }}
                    />
                }
            </div>
        </div>
        <div className="row">
            {/*<div className="col-lg-4 mt-4">*/}
            {/*    {stats.devices && <DashboardDevices data={stats.devices}/>}*/}
            {/*</div>*/}
            <div className="col-lg-12 mt-4">
                {stats.devices && <ChartDevicesByCategory data={stats.devices.types} distributed={true} />}
            </div>
            <div className="col-lg-12 mt-4">
                {stats.devices && <ChartDevicesByCategory data={stats.devices.subtypes} />}
            </div>
            <div className="col-lg-12 mt-4">
                {stats.countries && <ChartDevicesByCategory data={stats.countries} />}
            </div>

            <div className="col-lg-12 mt-4">
                {
                    stats.locations &&
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="mb-3 text-center">
                                    <Badge pill variant="danger"  className="mx-1">
                                        {stats.locations.filter(e => e.master).length} Users
                                    </Badge>
                                    <Badge pill variant="warning"  className="mx-1">
                                        {stats.locations.filter(e => !e.master).length} Shares
                                    </Badge>
                                </div>
                                <SimpleMap locations={stats.locations} />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="col-lg-4 mt-4">
                {
                    stats.platforms &&
                    <DashboardDevices
                        data={
                            stats.platforms.map(e => ({ ...e, key: e.key + ` -- ${e.value}` }))
                        }
                    />
                }
            </div>
        </div>


        {/*{layoutProps.demo === 'demo1' && <Demo1Dashboard />}*/}
        {/*{layoutProps.demo === 'demo2' && <Demo2Dashboard />}*/}
        {/*{layoutProps.demo === 'demo3' && <Demo3Dashboard />}*/}
        {/*{layoutProps.demo === 'demo4' && <Demo4Dashboard />}*/}
        {/*{layoutProps.demo === 'demo5' && <Demo5Dashboard />}*/}
        {/*{layoutProps.demo === 'demo6' && <Demo6Dashboard />}*/}
        {/*{layoutProps.demo === 'demo7' && <Demo7Dashboard />}*/}
    </>;
}
