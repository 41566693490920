import * as auth from "../../src/app/modules/Auth/_redux/authRedux";
import { toast } from 'react-toastify';
toast.configure();

export default function setupAxios(axios, store) {

    axios.defaults.baseURL = process.env.REACT_APP_SERVER || 'http://localhost:3000/api/';

    axios.interceptors.request.use(
        config => {
            const { auth: { authToken } } = store.getState();
            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }

            return config;
        },
        err => Promise.reject(err)
    );

    axios.interceptors.response.use(response => {
        // Do something with response data
        return response;
    }, error => {

        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error(error.message);
        }

        if (error && error.response && error.response.status === 401) {
            // Do Logout
            store.dispatch(auth.actions.logout());
            // window.location.reload();
        }
        // Do something with response error
        return Promise.reject(error);
    });

}
