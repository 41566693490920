import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function ChartLine({ data = [], title, subtitle }) {

    const [chart] = useState({
            series: [{
                name: "Readings Count",
                data: data.map(e => e.value)
            }],
            options: {
                chart: {
                    type: 'area',
                    height: 350,
                    zoom: { enabled: false }
                },
                dataLabels: { enabled: false },
                stroke: { curve: 'straight' },
                title: title || {},
                subtitle: subtitle || {},
                labels: data.map(e => e.key),
                xaxis: {
                    type: 'datetime',
                },
                yaxis: {
                    opposite: true
                },
                legend: {
                    horizontalAlign: 'left'
                }
            },
        }
    );
    return (
        <div className="card">
            <div className="card-content">
                <div className="card-body">
                    <div className="chart-wrap">
                        <div id="chart">
                            <ReactApexChart options={chart.options} series={chart.series} type="area" height={350}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
