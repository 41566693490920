import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  actionsLoading: false,
  entity: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const settingsSlice = createSlice({

  name: "settings",
  initialState: initialState,

  reducers: {

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },

    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },

    // getById
    fetched: (state, action) => {
      state.actionsLoading = false;
      state.entity = action.payload.model;
      state.error = null;
    },

    // update
    updated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entity = action.payload.model;
    }

  }

});
