/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
// import {useSelector} from "react-redux";

export function AsideSubmenu({ title, url, svg, isSub = false, children }) {

    // const {user} = useSelector(state => state.auth);
    // const isSuperAdmin = () => user.role === 'super-admin';

    const location = useLocation();

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };


    return (
        <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(url, true)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
        >
            <NavLink className="menu-link menu-toggle" to={url}>
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl(`/media/svg/icons/${svg}`)}/>
                </span>
                <span className="menu-text">{ title }</span>
            </NavLink>
            <div className="menu-submenu">
                <i className="menu-arrow"/>
                <ul className="menu-subnav">

                    <li className="menu-item menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                      <span className="menu-text">{ title }</span>
                    </span>
                    </li>
                    { children }
                </ul>
            </div>
        </li>
    );
}
