/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function AsideMenuSeperator({ title }) {
    return (
        <li className="menu-section ">
            <h4 className="menu-text">{ title }</h4>
            <i className="menu-icon flaticon-more-v2"></i>
        </li>
    );
}
