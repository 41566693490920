/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {useSelector} from "react-redux";

export function AsideMenuItem({ title, url, svg, isSub = false, isExternal = false, role }) {

    const {user} = useSelector(state => state.auth);

    const location = useLocation();

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open ` : "";
    };

    const checkRole = () => role ? (user.roles.includes(role)) : true;

    if (!checkRole()) return <></>;

    return (
        <li className={`menu-item ${getMenuItemActive(url)}`} aria-haspopup="true">
            { !isExternal ?
                (<NavLink className="menu-link" to={url}>
                    {
                        svg && (
                            <span className="svg-icon menu-icon">
                          <SVG src={toAbsoluteUrl(`/media/svg/icons/${svg}`)}/>
                        </span>
                        )
                    }
                    {isSub && (<i className="menu-bullet menu-bullet-dot"> <span/></i>)}
                    <span className="menu-text">{title}</span>
                </NavLink>) :
                (<a className="menu-link" href={url} target='_blank'>
                    {
                        svg && (
                            <span className="svg-icon menu-icon">
                          <SVG src={toAbsoluteUrl(`/media/svg/icons/${svg}`)}/>
                        </span>
                        )
                    }
                    {isSub && (<i className="menu-bullet menu-bullet-dot"> <span/></i>)}
                    <span className="menu-text">{title}</span>
                </a>)
            }
        </li>
    );
}
